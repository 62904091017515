<!-- @format -->
<script lang="ts">
  import type { ManageAPI } from "~/manage-api/types";
  import { getStoresContext, type StoresKey } from "~/stores";
  import { validity } from "./utils";
  export let request_store_key: StoresKey;
  import { getContext } from "svelte";
  import type { CampaignConfig } from "./CampaignItemListing.svelte";
  import { toRangeOfDaysOfTheWeek } from "~/util/date-n-time";
  import OpeningHoursRange from "~/components/opening-hours/OpeningHoursRange.svelte";

  export let offer: ManageAPI.Offer;

  const config: CampaignConfig = getContext("config");
  const { stores, site, T } = getStoresContext(request_store_key);
  
  $: locale = config.locale;
  $: dateFormat = config.date_format || "date";
  $: validityText = offer && $locale ? validity(offer, 'fi-FI', dateFormat) : '';
  
  // Font size configurations as CSS variables
  $: fontStyles = {
    '--title-font-size': config.modal_title_font_size || '31px',
    '--description-font-size': config.modal_description_font_size || '16px',
    '--long-description-font-size': config.modal_long_description_font_size || '16px',
    '--validity-font-size': config.modal_validity_font_size || '16px',
    '--opening-hours-font-size': config.modal_opening_hours_font_size || '14px'
  };
  
  $: storeName = offer?.store?.name ?? '';
  $: siteName = $site?.name ?? '';
  $: alt = storeName || siteName || '';

  $: storeId = offer?.store?.id;
  $: store = storeId !== undefined ? $stores.find((s) => s.id === storeId) : undefined;
  $: floor = store?.floor?.description;
  
  $: offerExt = offer as any;
  $: highResImage = offerExt.highResolutionImage || '';
  $: hasHighResImage = typeof highResImage === 'string' && highResImage.length > 0;
  $: regularImage = offer.images?.[0] || '';
</script>

{#if offer}
  <div class="row offer-modal" style={Object.entries(fontStyles).map(([key, value]) => `${key}: ${value}`).join(';')}>
    <div class="col-12"><h3>{alt}</h3></div>
    <div class="row">
      <div class="col-xl-4 col-lg-5">
        <div class="image-wrapper">
          {#if hasHighResImage}
            <img
              src={highResImage}
              alt={offer.title}
              class="offer-image" />
          {:else if regularImage}
            <img
              src={regularImage}
              alt={offer.title}
              class="offer-image" />
          {/if}
        </div>
        <div class="d-lg-block d-none opening-hours-container">
          {#if floor}
            <p class="mb-4">{floor}</p>
          {/if}
          {#if store?.unformattedOpeningHours?.length}
            {#each store.unformattedOpeningHours as item}
              <p class="mb-0">
                {@html toRangeOfDaysOfTheWeek(item.days, $locale)}:
                <OpeningHoursRange
                  locale={$locale}
                  {item}
                  opening_hours={store.unformattedOpeningHours}
                  t={$T} />
              </p>
            {/each}
          {/if}
        </div>
      </div>
      <div class="col-xl-8 col-lg-7 description">
        {#if validityText}
          <p class="mb-1 validity-text">
            <i>{$T`offers.valid`}: {validityText}</i>
          </p>
        {/if}
        <h2 class="offer-title">{offer.title}</h2>
        <p class="description1-2">
          {offer.description1} {offer.description2}
        </p>
        {#if offer.lowestPriceInXDays}
          <p class="omnibus-statement">
            <i>{$T`offers.daysLowestPrice`}: {offer.lowestPriceInXDays}</i>
          </p>
        {/if}
        <p class="long-description">
          {offer.longDescription}
        </p>

        <div class="d-lg-none opening-hours-container">
          {#if floor}
            <p class="mb-4">{floor}</p>
          {/if}
          {#if store?.unformattedOpeningHours?.length}
            {#each store.unformattedOpeningHours as item}
              <p class="mb-0">
                {@html toRangeOfDaysOfTheWeek(item.days, $locale)}:
                <OpeningHoursRange
                  locale={$locale}
                  {item}
                  opening_hours={store.unformattedOpeningHours}
                  t={$T} />
              </p>
            {/each}
          {/if}
        </div>
      </div>
    </div>
  </div>
{/if}

<style lang="scss">
  .offer-modal {
    --title-font-size: 31px;
    --description-font-size: 16px;
    --long-description-font-size: 16px;
    --validity-font-size: 16px;
    --opening-hours-font-size: 14px;
  }
  
  .description1-2 {
    font-weight: 500;
    font-size: var(--description-font-size);
  }
  
  .image-wrapper {
    width: 100%;
    margin-bottom: 1.5rem;
    
    @media screen and (min-width: 992px) {
      margin-bottom: 0.5rem;
    }
  }
  
  .offer-image {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
    background-color: #fff;
    display: block;
  }
  
  .opening-hours-container {
    margin-top: 1rem;
    font-size: var(--opening-hours-font-size);
  }
  
  .offer-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: var(--title-font-size);
  }
  
  h3 {
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 20px;
    max-width: 80%;
    @media screen and (min-width: 992px) {
      font-size: 30px;
    }
  }
  
  .validity-text {
    font-style: italic;
    font-size: var(--validity-font-size);
  }
  
  .long-description {
    margin-top: 1rem;
    font-size: var(--long-description-font-size);
  }
  
  .omnibus-statement {
    font-size: var(--description-font-size);
  }
  
  .description {
    @media screen and (max-width: 992px) {
      text-align: center;
    }
  }
</style>
