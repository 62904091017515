<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import type { ManageAPI } from "~/manage-api/types";
  import type { StoresKey } from "~/stores";
  import { createEventDispatcher, getContext } from "svelte";
  import type { CampaignConfig } from "./CampaignItemListing.svelte";
  import { user_likes } from "./store";
  import { validity } from "./utils";

  import { getFragmentConfigOr } from "../basic_component/util";

  export let fragment: Fragment;
  export let offer: ManageAPI.OfferExt;
  export let request_store_key: StoresKey;

  const { T, locale } = getStoresContext(request_store_key);
  const config: CampaignConfig = getContext('config');
  const dispatch = createEventDispatcher();
  
  $: venue_name = config.venue_name;
  $: dateFormat = config.date_format || "date";
  $: is_liked = user_likes.isLiked(offer.id);
  $: offerCardBgColor = getFragmentConfigOr("offerCardBckgdColor", fragment, "var(--brandColor1)");
  $: offerCardFontColor = getFragmentConfigOr("offerCardFontColor", fragment, "var(--brandColor2)");
  $: publisher_name = offer.store && offer.store.id ? offer.store.name : $venue_name;
  $: validityText = $locale ? validity(offer, $locale, dateFormat) : '';
  $: t = $T;
  
  $: highResImage = offer.highResolutionImage || '';
  $: thumbnailImage = offer.thumbnail || '';
  $: logoURL = offer.store?.logoURL || '';
  $: hasImage = !!highResImage || !!thumbnailImage || !!logoURL;
  
  function toggleLike(event: MouseEvent) {
    event.stopPropagation();
    is_liked = user_likes.toggle(offer.id);
  }

  function focusOffer(e: MouseEvent, offer: ManageAPI.OfferExt) {
    dispatch("click", { event: e, offer });
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  on:click={(event) => focusOffer(event, offer)}
  class="offers__card"
  class:two-per-row={config.mobile_offer_layout == "two/row"}
  style="
    color: {config.offer_card_font_color};
    --offerCardBgColor: {offerCardBgColor};
    --offerCardFontColor: {offerCardFontColor};
  "
>
  <div class="offer-wrapper">
    <div class="offers__link">
      <div class="offers__image-wrapper">
        <div
          style="border: {config.image_border} solid {config.image_border_color};"
          class="offers__image">
          <picture>
            {#if highResImage}
              <source
                srcset={highResImage}
                media="(min-width: 600px)" />
            {/if}
            {#if thumbnailImage}
              <img src={thumbnailImage} alt={offer.title} />
            {:else if logoURL}
              <img src={logoURL} alt={offer.store?.name || ''} />
            {/if}
          </picture>
        </div>
        {#if config.wish_list_enabled}
          <button
            style="background-color: {config.heart_background_color};
          color: {config.heart_color};"
            class="offers__heart"
            on:click={(event) => toggleLike(event)}>
            <i class="fa-heart far" class:fa={is_liked}></i>
          </button>
        {/if}
      </div>
    </div>
    <div
      class="offers__info"
      style="background-color: {config.offer_card_background_color};">
      <p class="offers__shop_link">
        {publisher_name}
      </p>
      {#if validityText}
        <p class="offers__validity">
          <i>{$T`offers.valid`}: {validityText}</i>
        </p>
      {/if}

      <p class="offers__title">{offer.title}</p>
      <p class="description1-2">
        <span class="description1">{offer.description1}</span> <br />
        <span class="description2">{offer.description2}</span>
      </p>
      <div class="viewMore mobile-only">
        {$T`offers.openInfo`}
        <svg
          width="100"
          height="100"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.7875 10.2875C23.0778 9.99648 23.4226 9.76559 23.8023 9.60805C24.1819 9.45051 24.589 9.36941 25 9.36941C25.411 9.36941 25.8181 9.45051 26.1977 9.60805C26.5774 9.76559 26.9222 9.99648 27.2125 10.2875L64.7125 47.7875C65.0035 48.0778 65.2344 48.4226 65.392 48.8023C65.5495 49.1819 65.6306 49.589 65.6306 50C65.6306 50.411 65.5495 50.818 65.392 51.1977C65.2344 51.5774 65.0035 51.9222 64.7125 52.2125L27.2125 89.7125C26.6257 90.2993 25.8299 90.6289 25 90.6289C24.1702 90.6289 23.3743 90.2993 22.7875 89.7125C22.2007 89.1257 21.8711 88.3298 21.8711 87.5C21.8711 86.6701 22.2007 85.8743 22.7875 85.2875L58.0813 50L22.7875 14.7125C22.4965 14.4222 22.2656 14.0774 22.108 13.6977C21.9505 13.3181 21.8694 12.911 21.8694 12.5C21.8694 12.089 21.9505 11.6819 22.108 11.3023C22.2656 10.9226 22.4965 10.5778 22.7875 10.2875Z"
            fill="black" />
          
        </svg>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .description1-2 {
    font-weight: 500;
    margin-bottom: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    
    @media screen and (max-width: 768px) {
      margin-bottom: 1rem;
      
      .two-per-row & {
        display: none;
      }
    }
  }
  
  .description1 {
    font-size: clamp(16px, 1.7vw, 20px);
  }
  
  .description2 {
    font-size: clamp(16px, 1.3vw, 16px);
  }

  .mobile-only {
    display: none !important;
    @media screen and (max-width: 768px) {
      display: flex !important;
    }
  }

  .viewMore {
    display: flex;
    align-items: center;
    font-size: clamp(16px, 1vw, 16px);
    margin-top: 1rem;
    
    @media screen and (max-width: 768px) {
      position: absolute;
      bottom: 0.8rem;
      left: 2rem;
      
      .two-per-row & {
        bottom: 0.8rem;
        left: 1rem;
        font-size: 14px;
        
        svg {
          width: 12px;
          height: 12px;
          margin-left: 6px;
        }
      }
    }
    
    @media screen and (max-width: 480px) {
      .two-per-row & {
        font-size: 12px;
        
        svg {
          width: 10px;
          height: 10px;
          margin-left: 4px;
        }
      }
    }
    
    svg {
      width: 15px;
      height: 15px;
      margin-left: 8px;
    }
    
    svg path {
      background-color: var(--brandColor2);
    }
  }
  
  .offer-wrapper {
    background-color: transparent;
    color: var(--offerCardFontColor);
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }
  
  .offers__image-wrapper {
    position: relative;
    padding: 15px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    aspect-ratio: 1 / 1;
    
    @media screen and (max-width: 768px) {
      padding: 10px;
      
      .two-per-row & {
        padding: 5px;
      }
    }
    
    @media screen and (max-width: 475px) {
      .two-per-row & {
        padding: 5px;
        width: 160px;
        height: 160px;
        margin: 0 auto;
      }
    }
  }
  
  .offers__image {
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 300px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    border-radius: 0;
    
    .two-per-row & {
      @media screen and (max-width: 768px) {
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        aspect-ratio: 1 / 1;
      }
      
      @media screen and (max-width: 475px) {
        width: 150px;
        height: 150px;
        max-width: 100%;
      }
    }
  }
  
  .offers__image img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
    background-color: white;
  }
  
  .offers__card {
    margin-bottom: 3rem;
    cursor: pointer;
    transition: 0.3s all ease;
    width: 100%;
    max-width: 367px;
    min-width: 160px;
    justify-self: center;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 20px;
    
    @media screen and (max-width: 768px) {
      padding: 0;
      
      .two-per-row & {
        width: 100%;
        max-width: 100%;
        margin: 0;
      }
    }
    
    @media screen and (max-width: 475px) {
      .two-per-row & {
        width: 170px;
        max-width: 170px;
        margin: 0 auto;
      }
    }
  }
  
  .offers__card:hover {
    transform: scale(1.05);
    border: none;
  }

  .offers__link {
    padding: 0;
    margin-bottom: -3rem;
    background-color: transparent;
    flex: 1;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: end;
    justify-content: center;
    
    @media screen and (max-width: 768px) {
      margin-bottom: -4rem;
      
      .two-per-row & {
        margin-bottom: -5.5rem;
      }
    }
    
    @media screen and (max-width: 475px) {
      .two-per-row & {
        margin-bottom: -3rem;
      }
    }
  }

  .offers__heart {
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 22px;
    height: 2rem;
    width: 2rem;
    border: none;
    i {
      padding: 4px 0px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    i:hover {
      -moz-transform: scale(1.05);
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
      transition-duration: 0.5s;
    }
  }

  .offers__info {
    overflow: visible;
    padding: 1.5rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    
    @media screen and (max-width: 768px) {
      padding: 1.25rem 2rem;
      padding-top: 4.5rem;
      padding-bottom: 3rem;
      
      .two-per-row & {
        padding: 1rem;
        padding-top: 6rem;
        padding-bottom: 3rem;
      }
    }
    
    @media screen and (max-width: 475px) {
      .two-per-row & {
        padding: 0.75rem;
        padding-top: 3rem;
        padding-bottom: 2rem;
      }
    }
  }

  .offers__title {
    font-size: clamp(18px, 2vw, 30px);
    word-wrap: break-word;
    font-weight: bold;
    margin: 0.5rem 0;
    min-height: 3.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    
    @media screen and (max-width: 768px) {
      font-size: clamp(16px, 4vw, 22px);
      min-height: 2.5rem;
      
      .two-per-row & {
        font-size: clamp(16px, 3vw, 18px);
        margin: 0.25rem 0;
      }
    }
  }

  .offers__shop_link {
    font-weight: bold;
    font-size: clamp(16px, 1.5vw, 20px);
    margin-bottom: 0;
    
    @media screen and (max-width: 768px) {
      .two-per-row & {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }

  .offers__validity {
    font-size: clamp(16px, 1vw, 16px);
    margin-bottom: 0;
    
    @media screen and (max-width: 768px) {
      .two-per-row & {
        font-size: 14px;
      }
      .offers__validity {
        font-size: 12px;
      }
      .description1-2{
        font-size: 13px;
      }
      .viewMore {
        font-size: 12px;
      }
    }
  }
</style>
