<!-- @format -->
<script lang="ts">
  import { onMount, createEventDispatcher } from "svelte";
  import { getFragmentConfigOr } from "../basic_component/util";
  import { getContext } from "svelte";
  import type { CampaignConfig } from "./CampaignItemListing.svelte";

  export let fragment: Fragment;
  export let showModal = false; // boolean
  export let request_store_key;

  const dispatch = createEventDispatcher();
  const config: CampaignConfig = getContext('config');
  let body: HTMLBodyElement;
  let dialog: HTMLDialogElement; // HTMLDialogElement

  $: if (dialog && showModal) dialog.showModal();

  const modalBgColor = getFragmentConfigOr("popupCardBckgdColor", fragment, "var(--brandColor1)");
  const modalFontColor = getFragmentConfigOr("popupCardFontColor", fragment, "var(--brandColor2)");
  const modalBorderRadius = config.modal_border_radius || "0px";
  
  onMount(() => {
    body = document.getElementsByTagName("body")[0];
  });
  
  function handleDialogClose() {
    showModal = false;
    dispatch('close');
  }
  
  $: {
    if (dialog && showModal && body) {
      body.classList.add("overflow-hidden");
    } else if (body && !showModal) {
      body.classList.remove("overflow-hidden");
    }
  }
</script>

<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<dialog
  on:click|self={() => dialog.close()}
  class="modal-container"
  bind:this={dialog}
  on:close={handleDialogClose}
  style="
    --modalBgColor:{modalBgColor};
    --modalFontColor:{modalFontColor};
    --modalBorderRadius:{modalBorderRadius};
  "
>
  <div class="modal_body">
    <button class="close" on:click={() => dialog.close()}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.50063 47.9619L0.604126 45.0654L21.3395 24.3301L0.604126 3.59474L3.50063 0.698242L24.236 21.4336L44.9713 0.698242L47.8678 3.59474L27.1325 24.3301L47.8678 45.0654L44.9713 47.9619L24.236 27.2266L3.50063 47.9619Z"
          fill="white" />
      </svg>
    </button>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div on:click|stopPropagation>
      <slot />
    </div>
  </div>
</dialog>

<style lang="scss">
  .modal_body {
    background-color: var(--modalBgColor);
    color: var(--modalFontColor);
    max-width: 90%;
    margin: auto;
    position: relative;
    padding: 16px 32px 32px;
    max-height: 85dvh;
    overflow: auto;
    @media screen and (min-width: 1280px) {
      max-width: 900px;
    }
  }

  dialog[open] {
    animation: zoom 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    overflow: auto;
  }
  .modal-container {
    background-color: var(--modalBgColor);
    border: none;
    border-radius: var(--modalBorderRadius);
  }
  .close {
    transition: all 0.2s ease;
    position: absolute;
    right: 30px;
    top: 12px;
    z-index: 1;
    @media screen and (min-width: 992px) {
      top: 16px;
    }
  }
  .close svg path {
    fill: var(--brandColor2);
  }
  .close svg {
    width: 20px;
    height: 20px;
    @media screen and (min-width: 992px) {
      width: 32px;
      height: 32px;
    }
  }
  .close:hover {
    transform: scale(1.05);
  }
  dialog::backdrop {
    background: rgba(0, 0, 0, 0.75);
  }
  @keyframes zoom {
    from {
      transform: scale(0.95);
    }
    to {
      transform: scale(1);
    }
  }
  dialog[open]::backdrop {
    animation: fade 0.2s ease-out;
  }
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
</style>
